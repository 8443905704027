import Vue from "vue";
import VueRouter from "vue-router";
// import getRegister from "../views/register/index.vue";
// 审核的导入

// orgApprove

Vue.use(VueRouter);
// subjectApprove
const routes = [
  { path: "/", redirect: "/login" },
  {
    path: "/login",
    component: () => import("../components/Login.vue")
  },
  {
    path: "/home",
    component: () => import("../components/home.vue"),
    redirect: "/index",
    children: [
      {
        path: "/index",
        component: () => import("../components/welcome.vue"),
        meta: { keepAlive: false }
      },
      {
        path: "/staffAuthority",
        component: () => import("../views/accountNumber/index.vue"),
        meta: { keepAlive: false }
      }, //
      {
        path: "/orgManage",
        component: () =>
          import("../views/memberManagement/memberManagement.vue"),
        meta: { keepAlive: false }
      },
      {
        path: "/orgApprove",
        component: () => import("../views/memberManagement/memberAudit.vue"),
        meta: { keepAlive: false }
      },
      {
        path: "/demo",
        component: () => import("../views/memberManagement/demo.vue"),
        meta: { keepAlive: false }
      },
      {
        path: "/accountNumber",
        name: "accountNumber",
        component: () => import("../views/accountNumber/accountumber.vue"),
        meta: { keepAlive: false }
      }, //
      {
        path: "/industryApprove",
        component: () => import("../views/examine/industryApprove.vue"),
        meta: { keepAlive: false }
      },
      // vehicleAudit
      {
        path: "/registerApprove",
        component: () => import("../views/examine/registerApprove.vue"),
        meta: { keepAlive: false }
      },
      // 车辆信息审核页面
      {
        path: "/vehicleApprove",
        component: () => import("../views/examine/vehicleAudit.vue"),
        meta: { keepAlive: false }
      },
      {
        path: "/lookCar",
        component: () => import("../views/examine/lookCar.vue"),
        meta: { keepAlive: false }
      },
      {
        path: "/subjectApprove",
        component: () => import("../views/examine/subjectApprove.vue"),
        meta: { keepAlive: false }
      },

      {
        path: "/focusMain",
        component: () => import("../views/fousManagement/focusMain.vue"),
        meta: { keepAlive: false }
      },
      {
        path: "/focusCar",
        component: () => import("../views/fousManagement/focusCar.vue"),
        meta: { keepAlive: false }
      }, //
      {
        path: "/industryReport",
        component: () => import("../views/industrySubject/industryReport.vue"),
        meta: { keepAlive: false }
      }, //
      // 机构管理
      {
        path: "/memberManagementLook",
        component: () =>
          import("../views/memberManagement/memberManagementLook.vue"),
        meta: { keepAlive: false }
      }, //
      //用户管理
      {
        path: "/management",
        component: () => import("../views/memberManagement/management.vue"),
        meta: { keepAlive: false }
      }, //
      {
        path: "/demoa",
        component: () => import("../views/memberManagement/demoa.vue"),
        meta: { keepAlive: false }
      }, //
      {
        path: "/offerApprove",
        component: () => import("../views/examine/offerApprove.vue"),
        meta: { keepAlive: false }
      },
      {
        path: "/addVehicleOffer",
        component: () => import("../views/report/addVehicleOffer.vue"),
        meta: { keepAlive: false }
      },
      {
        path: "/getVehicleOffers",
        component: () => import("../views/report/getVehicleOffers.vue"),
        meta: { keepAlive: false }
      },
      {
        path: "/pictureImage",
        component: () => import("../views/report/pictureImage.vue"),
        meta: { keepAlive: false }
      },
      // 装修管理
      {
        path: "/bannerManage",
        component: () => import("../views/decoration/management.vue"),
        meta: { keepAlive: false }
      },
      {
        path: "/volumePricing",
        component: () => import("../views/salesVolume/volumePricing.vue"),
        meta: { keepAlive: false }
      },
      {
        path: "/volumeDetails",
        component: () => import("../views/salesVolume/volumeDetails.vue"),
        meta: { keepAlive: false }
      },
      // 团队管理/用户列表
      {
        path: "/userList",
        component: () => import("../views/teamManagement/userList.vue"),
        meta: { keepAlive: false }
      },
      // 团队管理/团队列表
      {
        path: "/teamList",
        component: () => import("../views/teamManagement/teamList.vue"),
        meta: { keepAlive: false }
      },
      // 会员管理/会员列表
      {
        path: "/memberList",
        component: () => import("../views/vipManagement/memberList.vue"),
        meta: { keepAlive: false }
      },
      // 会员管理/会员记录
      {
        path: "/memberRecords",
        component: () => import("../views/vipManagement/memberRecords.vue"),
        meta: { keepAlive: false }
      },
      // 查车价 定价
      {
        path: "/customized",
        component: () => import("../views/checkCarPrices/customized.vue"),
        meta: { keepAlive: false }
      },

       // 车型库 品牌列表
       {
        path: "/brandsList",
        component: () => import("../views/vehicleModelLibrary/brandsList.vue"),
        meta: { keepAlive: false }
      },
    ]
  }
];

const router = new VueRouter({
  routes
});
router.beforeEach((to, from, next) => {
  if (to.path == "/addVehicleOffer") {
    // console.log("我进入列表页面");
    to.meta.keepAlive = true;
    if (from.path == "/pictureImage") {
      // console.log("我是图片进来的");
      to.meta.keepAlive = true;
    }
  }
  // console.log(to);
  // console.log(from);
  // to 将要访问的路径
  // from表示从哪个路径访问而来
  // next 是一个函数 表示放行
  // next() 放行  next('/login') 强制跳转到括号里边的路径
  if (to.path === "/login") return next();
  // 获取token
  const tokenStr = window.sessionStorage.getItem("token");
  if (!tokenStr) return next("/login");
  next();
});

export default router;
