// 生产入口文件
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
// 导入全局样式表
import "./assets/css/global.css";
import axios from "axios"; //导入axios
import moment from "moment";
// 图片插件

import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
// 导入第三方进度条中的js和css
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'
Vue.use(Viewer);
Viewer.setDefaults({
    Options: {
        inline: true,
        button: true,
        navbar: true,
        title: true,
        toolbar: true,
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: true,
        url: "data-source"
    }
});

// 配置请求的根路径
// axios.defaults.baseURL = 'http://192.168.3.86:9093/staffPlatform/'
// axios.defaults.baseURL = 'https://apisgu.chinaucv.com/clientPlatform/'
axios.defaults.baseURL = "https://apisgu.chinaucv.com/staffPlatform/";
// axios.defaults.baseURL = '/api/staffPlatform'
// 配置请求拦截器 为每次请求挂载`Authorization` 请求头
//  在request拦截器展示进度条  Nprogress.start()
axios.interceptors.request.use(config => {

    Nprogress.start()
    // 为请求头对象 添加token验证的Authorization字段
    config.headers.Token = window.sessionStorage.getItem("token");
    return config;
});
// 在reponse拦截器隐藏进度条Nprogress.done()
axios.interceptors.response.use(config => {
    Nprogress.done()
    return config
})
Vue.prototype.$http = axios; // 给vue原型添加一个属性$http为axios对象 给Vue添加一个原型属性$http之后，就可以在任意的Vue界面中通过this访问$http
Vue.prototype.$moment = moment;

Vue.config.productionTip = false;
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
