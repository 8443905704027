import Vue from "vue";
import {
    Button,
    Form,
    FormItem,
    Input,
    Message,
    Container,
    Header,
    Aside,
    Main,
    Menu,
    Submenu,
    MenuItemGroup,
    MenuItem,
    Dialog,
    Card,
    Row,
    Col,
    Switch,
    Table,
    TableColumn,
    Image,
    Breadcrumb,
    BreadcrumbItem,
    Select,
    Option,
    Cascader,
    DatePicker,
    MessageBox,
    Upload,
    Tabs,
    TabPane,
    Pagination,
    OptionGroup,
    Popover,
    Tag,
    Carousel,
    CarouselItem,
    Radio,
    RadioGroup,
    Tree,
    Loading,
    Drawer,
    Divider,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Checkbox,
} from "element-ui";
Vue.use(CarouselItem)
Vue.use(Carousel)
Vue.use(Checkbox)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Drawer)
Vue.use(Divider)
Vue.use(Radio)
Vue.use(Tree)
Vue.use(RadioGroup)
Vue.use(Upload);
Vue.use(TabPane);
Vue.use(Loading);
Vue.use(Tabs);
Vue.use(DatePicker);
Vue.use(Cascader);
Vue.use(Option);
Vue.use(Select);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Image), Vue.use(TableColumn), Vue.use(Table);
Vue.use(Switch);
Vue.use(Row);
Vue.use(Col);
Vue.use(Card);
Vue.use(Dialog);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItemGroup);
Vue.use(MenuItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Pagination);
Vue.use(Button);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(OptionGroup);
Vue.use(Popover);
Vue.use(Tag);

Vue.prototype.$message = Message; //- 这个Message不是组件，所以不用use
//- 而是需要挂载到Vue的原型上，这样可以在任意组件中通过this访问
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$loading = Loading.service;
