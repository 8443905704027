<template>
  <div id="app">
    <!-- <keep-alive>
          <router-view v-if="$route.meta.keepAlive" class="router-view">
          </router-view>
      </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" class="router-view" ></router-view>-->
    <!-- 路由占位符 -->
    <!-- <keep-alive>
      <router-view></router-view>
    </keep-alive>-->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>

<style>
</style>
